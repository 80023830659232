import React, { FC } from "react";
import { colors } from "~/utils/colors";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";

type TelephoneNumberPanelType = {};

export const TelephoneNumberPanel: FC<TelephoneNumberPanelType> = () => {
  return (
    <>
      <div className="telPanel">
        <div className="telIcon">
          <FaPhoneAlt style={{ fontSize: 14, color: colors.white }} />
        </div>
        <div className="telDoc">
          <div className="textWrapper">
            <text className="text">お電話でのお問い合わせ</text>
          </div>
          <div className="numberWrapper">
            <text className="number">03-5656-9966</text>
          </div>
          <div className="timeWrapper">
            <text className="time">営業時間：10:00〜18:00</text>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .telPanel {
            display: flex;
            align-items: center;
          }

          .telIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${colors.orange};
            height: 24px;
            width: 24px;
            border-radius: 50%;
          }

          .telDoc {
            display: flex;
            flex-direction: column;
            margin-left: 14px;
          }

          .textWrapper {
            line-height: 1;
          }

          .text {
            font-size: 12px;
            font-weight: bold;
          }

          .numberWrapper {
            line-height: 1;
          }

          .number {
            font-size: 24px;
            font-weight: bold;
            color: ${colors.orange};
          }

          .timeWrapper {
            line-height: 1;
          }

          .time {
            font-size: 10px;
            color: ${colors.defaultText};
          }
        `}
      </style>
    </>
  );
};
