// TODO: gray toneを整理する
const Pallet = {
  white: `#fff`,
  black: `#3E3834`,
  darker: `#131110`,
  dark: `#41423C`,
  gray: `#f5f5f5`,
  gray2: `#999`,
  gray3: `#efefef`,
  gray4: `#D2D0CF`,
  grayMid: `#716760`,
  lightGray: `#f9f8f7`,
  creamGray: `#f5f5f0`,
  creamGray2: `#e9e9e4`,
  littleBrightGray: `#969696`,
  orange: `#ff6400`,
  subOrange: `#ffD5C2`,
  lightOrange: `#fff0eB`,
  purple: `#86a1f3`,
  lightPurple: `#c3dfdd`,
  red: `#ff6673`,
  lightRed: `#f5c6c3`,
  darkBlue: `#0F78D8`,
  lightBlue: `#76C8C8`,
};

const Semantics = {
  darkerText: Pallet.darker,
  darkText: Pallet.dark,
  defaultText: Pallet.black,
  lightText: Pallet.grayMid,
  whiteText: Pallet.white,
  defaultBg: Pallet.gray,
  brand: Pallet.orange,
  brandAccent: Pallet.purple,
  brandGradient: `linear-gradient(to bottom right, #EC7F18 30%, #EC553F)`,
  bgGrayGradient: `linear-gradient(180deg, ${Pallet.white} 10%, ${Pallet.creamGray} 60%);`,
  error: Pallet.red,
  border: Pallet.gray4,
};

export const colors = {
  ...Pallet,
  ...Semantics,
};
